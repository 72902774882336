.Chapter{
	margin:40px 60px 0 60px;
	padding-bottom:60px;
	display:flex;
	justify-content:center;
/*	flex-direction: column;
*/}
.Chapter h3{
	text-align:center;
	width:250px;
	margin:20px auto;
	margin-bottom:40px;
	padding:10px;
	line-height: .8em;
	border-bottom:solid 1px var(--black-3);
	border-top:solid 1px var(--black-3);
}
.verse{
	padding:5px 30px;
}
.activeVerse{
	border-left:solid 5px var(--black-5);
	padding:5px 25px;
	margin-left:30px;
}
.verse.activeVerse span{
	color:var(--black-5);
}
.verse p{
	padding:0;
	margin-block-start: 0;
	margin-block-end: 0;
}
.verse span{
	font-weight: bold;
	color: var(--black-3);
	margin-right:5px;
	padding-left:3px;
	line-height:.5em;
	text-align:center;
}
.container{
	max-width:600px;
}
@media (max-width:600px){
	.Chapter{
		margin:0 10px 0 10px;
		padding-bottom:60px;
	}
	.Chapter h3{
		margin-top:10px;
	}
	.verse{
		padding:5px 15px;
	}
	.activeVerse{
		border-left:solid 3px var(--black-5);
		padding:5px 12px;
		margin-left:15px;
	}
}