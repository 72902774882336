.Nav {
	min-width: 299px;
	background-color: var(--white-5);
	position: -webkit-sticky;
	position: sticky;
	overflow-y: scroll;
	height: 100vh;
	top: 0;
	right: -300px;
	bottom: 0;
	z-index: 300;
	box-shadow: 0 0 5px var(--black-2-t);
	transition: min-width 0.3s ease-in, right 0.3s ease-in;
}
/*=====TAGS=====*/
.Nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.Nav h3 {
	padding: 13px 30px;
}
.Nav a {
	text-decoration: none;
	color: inherit;
}

/*=====CLASSES=====*/
/*header*/
.headerWrap {
	display: flex;
	width: 100%;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 104;
	background: var(--white-5);
}
.header {
	width: 100%;
	padding: 15px;
}
.close {
	margin: 0;
	font-family: "Montserrat";
	font-weight: 500;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 20px;
}
/*volumes*/
.volume {
	padding: 5px 30px;
	display: block;
	cursor: pointer;
}
/*books*/
.book {
	padding: 5px 30px;
	cursor: pointer;
	display: block;
}
.book span {
	float: right;
	font-size: 26px;
	font-weight: 300;
	line-height: 25px;
	vertical-align: middle;
}
.book span:before {
	content: "+";
}
/*chapters*/
.chapter {
	padding: 5px 30px 5px 50px;
	cursor: pointer;
	display: block;
}

/*=====DYNAMIC CLASSES=====*/
.volume:hover,
.book:hover,
.chapter:hover {
	background: var(--black-1);
	transition: all ease-in 0.3s;
}
.activeVolume,
.activeBook,
.activeChapter {
	background: var(--black-1);
	position: -webkit-sticky;
	position: sticky;
}
.activeVolume {
	top: 50px;
	z-index: 103;
}
.activeBook {
	top: 82px;
	z-index: 102;
}
.activeChapter {
	top: 114px;
	padding: 5px 30px;
}
.activeBook span {
	line-height: 0px;
	position: relative;
	left: -2px;
}
.activeBook span:before {
	content: "_";
}

/*RESPONSIVE*/
@media (max-width: 900px) {
	.openAside {
		min-width: 100%;
		right: 0;
		transition: all 0.3s ease-in;
	}
	.volume,
	.book,
	.chapter {
		text-align: center;
		padding: 10px 30px;
	}
}
