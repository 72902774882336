@import url(https://fonts.googleapis.com/css?family=Open+Sans|Montserrat:300,500&display=swap);
body {
	margin: 0;
	font-family: "open sans", sans-serif;
	color: #333333;
	color: var(--black-8);
	width: 100%;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
}
h1,
h2,
h3,
h4 {
	font-family: "Montserrat", sans-serif;
	margin: 10px;
	font-weight: 500;
	color: #333333;
	color: var(--black-8);
}
h1 {
	font-size: 46px;
	letter-spacing: 0.1em;
	font-weight: 400;
	/*	text-transform: uppercase;
*/
}
p {
	font-size: 18px;
	line-height: 1.7em;
	font-weight: 300;
}
hr {
	margin: 10px;
	border: 1px solid #e5e5e5;
	border: 1px solid var(--black-1);
}
a {
	text-decoration: none;
	color: rgb(2, 140, 210);
	color: var(--blue-5);
}
a:hover {
	color: #58b3e2;
	color: var(--blue-3);
}
#App {
	height: 100vh;
	box-sizing: content-box;
	overflow-y: scroll;
	overflow-x: hidden;
}

/*======GLOBAL IDS======*/
#Loading {
	position: absolute;
	top: 53px;
	bottom: 0;
	right: 5px;
	left: 0;
	z-index: 1000;
	background-color: white;
}
#NotFound {
	position: fixed;
	height: 100vh;
	top: 0;
	bottom: 0;
	right: 3px;
	left: 0;
	z-index: 1000;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
#NotFound h3 {
}
#NotFound a {
	padding: 20px 40px;
}

/*=====GLOBAL CLASSES=====*/
.fade-in {
	-webkit-animation: fade-in 0.5s;
	        animation: fade-in 0.5s;
	height: 100%;
}

/*=====GLOBAL ANIMATIONS=====*/
@-webkit-keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/*=====Variables=====*/
:root {
	--white-1: #fcfcfc;
	--white-5: #f2f2f2;
	--white-9: #e8e8e8;
	--black-1: #e5e5e5;
	--black-2: #cccccc;
	--black-3: #b2b2b2;
	--black-4: #999999;
	--black-5: #7f7f7f;
	--black-6: #666666;
	--black-7: #4c4c4c;
	--black-8: #333333;
	--black-9: #191919;
	--black-1-t: rgba(0, 0, 0, 0.1);
	--black-2-t: rgba(0, 0, 0, 0.2);
	--black-3-t: rgba(0, 0, 0, 0.3);
	--black-4-t: rgba(0, 0, 0, 0.4);
	--black-5-t: rgba(0, 0, 0, 0.5);
	--black-6-t: rgba(0, 0, 0, 0.6);
	--black-7-t: rgba(0, 0, 0, 0.7);
	--black-8-t: rgba(0, 0, 0, 0.8);
	--black-9-t: rgba(0, 0, 0, 0.9);
	--blue-3: #58b3e2;
	--blue-5: rgb(2, 140, 210);
	--blue-5-t: rgba(2, 140, 210, 0.7);
}

/*experimental*/
* {
	-webkit-overflow-scrolling: touch;
}

.Main_Main__5WHcZ{
	display:flex;
	position:relative;
	left:0;
	min-width:calc(100% + 300px);
	background-color:white;
	-webkit-transition:min-width ease-in .3s;
	transition:min-width ease-in .3s;
}
.Main_openAside__2gn_T{
	min-width:100%;
	-webkit-transition:all ease-in .3s;
	transition:all ease-in .3s;
}
@media (min-width:900px){
	.Main_Main__5WHcZ{
		min-width:100%;
	}
	.Main_openAside__2gn_T{
		min-width:calc(100% + 300px);
	}
}
.Content_Content__3zPtt {
	flex: 1 0;
	position: relative;
	top: 0;
	max-width: 100vw;
	padding-top: 15px;
}
.Content_Home__mhbwT,
.Content_Volume__2boUR,
.Content_Book__T49GM,
.Content_Chapter__1guLv {
	display: flex;
	justify-content: center;
	margin: 40px;
}
.Content_Nav__3G2RA {
	position: -webkit-sticky;
	position: sticky;
	top: 40vh;
	z-index: 200;
}

.Content_prev__24Lew,
.Content_next__1KbkN {
	position: absolute;
	display: flex;
	flex: 1 1;
	margin-top: 20px;
	padding: 30px;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	line-height: 0px;
	cursor: pointer;
	z-index: 201;
}
.Content_prev__24Lew div,
.Content_next__1KbkN div {
	-webkit-transform: scale(0.8, 1.2);
	        transform: scale(0.8, 1.2);
	pointer-events: none;
}
.Content_prev__24Lew:hover,
.Content_next__1KbkN:hover {
	color: var(--black-3);
}
.Content_next__1KbkN {
	right: 0;
}

/* media queries*/
@media (max-width: 600px) {
	.Content_Content__3zPtt {
		padding-top: 0;
	}
	.Content_Nav__3G2RA {
		top: calc(100vh - 40px);
		height: 42px;
		background: white;
		box-shadow: 0 0 5px var(--black-2-t);
	}
	.Content_prev__24Lew,
	.Content_next__1KbkN {
		position: fixed;
		width: 50vw;
		bottom: 0;
		padding: 20px 0;
		background: white;
	}
	.Content_prev__24Lew {
		border-right: solid 0.5px var(--black-2);
	}
	.Content_prev__24Lew:hover,
	.Content_next__1KbkN:hover {
		background-color: var(--white-5);
	}
}

.Body_Body__2T1Jd{
	width:100vw;
	position:relative;
	opacity:1;
	-webkit-transition:opacity 0s ease-in .3s;
	transition:opacity 0s ease-in .3s;
}


@media(max-width:900px){
	.Body_openAside__21YjK{
		opacity: 0;
		-webkit-transition:opacity 0s ease-in 0s;
		transition:opacity 0s ease-in 0s;
	}
}
.Nav_Nav__2ZMbX {
	min-width: 299px;
	background-color: var(--white-5);
	position: -webkit-sticky;
	position: sticky;
	overflow-y: scroll;
	height: 100vh;
	top: 0;
	right: -300px;
	bottom: 0;
	z-index: 300;
	box-shadow: 0 0 5px var(--black-2-t);
	-webkit-transition: min-width 0.3s ease-in, right 0.3s ease-in;
	transition: min-width 0.3s ease-in, right 0.3s ease-in;
}
/*=====TAGS=====*/
.Nav_Nav__2ZMbX ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.Nav_Nav__2ZMbX h3 {
	padding: 13px 30px;
}
.Nav_Nav__2ZMbX a {
	text-decoration: none;
	color: inherit;
}

/*=====CLASSES=====*/
/*header*/
.Nav_headerWrap__34PAi {
	display: flex;
	width: 100%;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 104;
	background: var(--white-5);
}
.Nav_header__25wnv {
	width: 100%;
	padding: 15px;
}
.Nav_close__2ycJp {
	margin: 0;
	font-family: "Montserrat";
	font-weight: 500;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 20px;
}
/*volumes*/
.Nav_volume__OQ-gk {
	padding: 5px 30px;
	display: block;
	cursor: pointer;
}
/*books*/
.Nav_book__3T-ej {
	padding: 5px 30px;
	cursor: pointer;
	display: block;
}
.Nav_book__3T-ej span {
	float: right;
	font-size: 26px;
	font-weight: 300;
	line-height: 25px;
	vertical-align: middle;
}
.Nav_book__3T-ej span:before {
	content: "+";
}
/*chapters*/
.Nav_chapter__3LW7m {
	padding: 5px 30px 5px 50px;
	cursor: pointer;
	display: block;
}

/*=====DYNAMIC CLASSES=====*/
.Nav_volume__OQ-gk:hover,
.Nav_book__3T-ej:hover,
.Nav_chapter__3LW7m:hover {
	background: var(--black-1);
	-webkit-transition: all ease-in 0.3s;
	transition: all ease-in 0.3s;
}
.Nav_activeVolume__2SnhA,
.Nav_activeBook__3sLkU,
.Nav_activeChapter__21fma {
	background: var(--black-1);
	position: -webkit-sticky;
	position: sticky;
}
.Nav_activeVolume__2SnhA {
	top: 50px;
	z-index: 103;
}
.Nav_activeBook__3sLkU {
	top: 82px;
	z-index: 102;
}
.Nav_activeChapter__21fma {
	top: 114px;
	padding: 5px 30px;
}
.Nav_activeBook__3sLkU span {
	line-height: 0px;
	position: relative;
	left: -2px;
}
.Nav_activeBook__3sLkU span:before {
	content: "_";
}

/*RESPONSIVE*/
@media (max-width: 900px) {
	.Nav_openAside__fkazO {
		min-width: 100%;
		right: 0;
		-webkit-transition: all 0.3s ease-in;
		transition: all 0.3s ease-in;
	}
	.Nav_volume__OQ-gk,
	.Nav_book__3T-ej,
	.Nav_chapter__3LW7m {
		text-align: center;
		padding: 10px 30px;
	}
}

.Title_Title__2aKLW {
	height: 300px;
}
.Title_Title__2aKLW h1,
.Title_Title__2aKLW h2 {
	color: white;
}
.Title_background__EDcJG {
	width: 100%;
	height: 300px;
	object-fit: cover;
	object-position: center top;
	position: relative;
	z-index: 400;
}
.Title_overlay__3rK_a {
	height: 300px;
	position: relative;
	z-index: 401;
	top: -305px;
	right: 0;
	left: 0;
	background: var(--black-4-t);
}
.Title_textContainer__YgjQE {
	position: relative;
	top: -610px;
	z-index: 402;
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	height: 300px;
}
.Title_textWrap__394IE {
	max-width: 650px;
	margin: 40px;
	padding-right: 50px;
	border-right: 5px solid white;
	overflow: hidden;
}
.Title_textWrap2__2aMCQ {
	text-align: right;
	-webkit-animation: Title_slide-left__2DJsQ 0.5s;
	animation: Title_slide-left__2DJsQ 0.5s;
}
@media (max-width: 665px) {
	.Title_textContainer__YgjQE {
		justify-content: center;
	}
	.Title_textWrap__394IE {
		padding-right: 0;
		border-right: none;
		overflow: hidden;
	}
	.Title_textWrap__394IE h1 {
		border-bottom: 3px solid white;
		padding-bottom: 10px;
		font-size: 30px;
	}
	.Title_textWrap__394IE h3 {
		font-size: 24px;
	}
	.Title_textWrap2__2aMCQ {
		text-align: center;
		-webkit-animation: none;
		animation: none;
	}
}
@-webkit-keyframes Title_slide-left__2DJsQ {
	from {
		opacity: 0;
		-webkit-transform: translateX(250px);
		        transform: translateX(250px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}
@keyframes Title_slide-left__2DJsQ {
	from {
		opacity: 0;
		-webkit-transform: translateX(250px);
		        transform: translateX(250px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

.Toolbar_Toolbar__2tfB5{
	background:white;
	position: -webkit-sticky;
	position: sticky;
	top:0;
	max-width:100vw;
	height:50px;
	display:flex;
	justify-content: center;
	align-items:center;
	z-index: 200;
	box-shadow: 0 0 5px var(--black-2-t);
}
.Toolbar_home__32UEs{
	width:100%;
	padding:13px 10px 13px 40px;
	color:var(--black-8);
}
.Toolbar_reference__2R9fm{
	min-width: 150px;
	width:100%;
	text-align:center;
	padding:0 10px;
	color:var(--black-3);
}
.Toolbar_asideToggle__1dg9K{
	width:100%;
	text-align: right;
	padding:13px 40px 13px 10px;
	cursor:pointer;
}
.Toolbar_home__32UEs:hover{
	color:var(--black-8);
}
@media(max-width:600px){
	.Toolbar_home__32UEs{
		width:100%;
		min-width: 60px;
		text-align: center;
		padding:13px 0;
	}
	.Toolbar_reference__2R9fm{
		font-size: 12px;
	}
	.Toolbar_asideToggle__1dg9K{
		width:100%;
		min-width:60px;
		text-align: center;
		padding:13px 0;
}
}
.Home_Home__2BFJp {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.Home_Home__2BFJp img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	object-position: center top;
}
.Home_container__2drjE {
	margin: 20px 60px;
	max-width: 600px;
}
.Home_volumeList__2pVEr {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.Home_title__1XiS7 {
	position: absolute;
	top: 0;
	z-index: 1;
	padding: 5px;
	margin: 0;
	height: 200px;
	text-align: center;
	display: flex;
	align-items: center;
	pointer-events: none;
	color: white;
}
.Home_volume__bXPGF {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-transition: -webkit-transform 0.3s ease-in;
	transition: -webkit-transform 0.3s ease-in;
	transition: transform 0.3s ease-in;
	transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
	min-width: 300px;
	max-width: 50%;
}

/* .volume:nth-last-child(1):nth-child(odd) {
	max-width: 100%;
} */
.Home_overlay__hMLdZ {
	height: 200px;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background: var(--black-3-t);
	-webkit-transition: background 0.3s ease-in;
	transition: background 0.3s ease-in;
}
.Home_volume__bXPGF:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	box-shadow: 0 0 5px var(--black-2-t);
	z-index: 2;
	-webkit-transition: -webkit-transform 0.3s ease-in;
	transition: -webkit-transform 0.3s ease-in;
	transition: transform 0.3s ease-in;
	transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}
.Home_volume__bXPGF:hover .Home_overlay__hMLdZ {
	background: var(--black-1-t);
	-webkit-transition: background 0.3s ease-in;
	transition: background 0.3s ease-in;
}
.Home_quote__25nfs {
	margin-top: 20px;
	line-height: 1.5em;
	-webkit-animation: Home_slide-left__1Grq3 2s;
	animation: Home_slide-left__1Grq3 2s;
}
.Home_source__36vah {
	margin-bottom: 20px;
	color: var(--black-3);
	text-align: right;
	-webkit-animation: Home_slide-right__360Fh 2s;
	animation: Home_slide-right__360Fh 2s;
}

@-webkit-keyframes Home_slide-left__1Grq3 {
	from {
		opacity: 0;
		-webkit-transform: translateX(100px);
		        transform: translateX(100px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

@keyframes Home_slide-left__1Grq3 {
	from {
		opacity: 0;
		-webkit-transform: translateX(100px);
		        transform: translateX(100px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}
@-webkit-keyframes Home_slide-right__360Fh {
	from {
		opacity: 0;
		-webkit-transform: translateX(-100px);
		        transform: translateX(-100px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}
@keyframes Home_slide-right__360Fh {
	from {
		opacity: 0;
		-webkit-transform: translateX(-100px);
		        transform: translateX(-100px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

@media (max-width: 650px) {
	.Home_container__2drjE {
		margin: 20px;
	}
	.Home_volumeList__2pVEr {
		flex-direction: column;
	}
	.Home_volume__bXPGF {
		max-width: 100%;
	}
}

.Volume_Volume__7iadk {
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 60px;
}

.Volume_selectorWrap__3hu4W {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 800px;
	margin: 0 65px;
	flex: 1 0;
}
.Volume_selector__2rnPC {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
	padding: 10px;
	height: 40px;
	min-width: 200px;
	background: var(--blue-5);
	color: white;
	-webkit-transition: -webkit-transform 0.3s ease-in;
	transition: -webkit-transform 0.3s ease-in;
	transition: transform 0.3s ease-in;
	transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}
.Volume_selector__2rnPC:hover {
	color: white;
	background: var(--blue-3);
	-webkit-transform: scale(1.15);
	        transform: scale(1.15);
	box-shadow: 0 0 5px var(--black-2-t);
	z-index: 2;
	-webkit-transition: -webkit-transform 0.3s ease-in;
	transition: -webkit-transform 0.3s ease-in;
	transition: transform 0.3s ease-in;
	transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}

.Volume_source__4A6na {
	display: block;
	margin-bottom: 20px;
	color: var(--black-3);
	text-align: right;
}

@media (max-width: 650px) {
	.Volume_selectorWrap__3hu4W {
		flex-direction: column;
		margin: 0;
		margin-top: 15px;
		width: 100%;
	}
	.Volume_selector__2rnPC {
		margin: 1px 0;
	}

	/*	.Chapter{
		margin:0 10px 0 10px;
		padding-bottom:60px;
	}
	.verse{
		padding:5px 15px;
	}
	.activeVerse{
		border-left:solid 3px var(--black-5);
		padding:5px 12px;
		margin-left:15px;
	}*/
}

.Chapter_Chapter__1qHlj{
	margin:40px 60px 0 60px;
	padding-bottom:60px;
	display:flex;
	justify-content:center;
/*	flex-direction: column;
*/}
.Chapter_Chapter__1qHlj h3{
	text-align:center;
	width:250px;
	margin:20px auto;
	margin-bottom:40px;
	padding:10px;
	line-height: .8em;
	border-bottom:solid 1px var(--black-3);
	border-top:solid 1px var(--black-3);
}
.Chapter_verse__3X9sH{
	padding:5px 30px;
}
.Chapter_activeVerse__1n1qS{
	border-left:solid 5px var(--black-5);
	padding:5px 25px;
	margin-left:30px;
}
.Chapter_verse__3X9sH.Chapter_activeVerse__1n1qS span{
	color:var(--black-5);
}
.Chapter_verse__3X9sH p{
	padding:0;
	-webkit-margin-before: 0;
	        margin-block-start: 0;
	-webkit-margin-after: 0;
	        margin-block-end: 0;
}
.Chapter_verse__3X9sH span{
	font-weight: bold;
	color: var(--black-3);
	margin-right:5px;
	padding-left:3px;
	line-height:.5em;
	text-align:center;
}
.Chapter_container__KFsfI{
	max-width:600px;
}
@media (max-width:600px){
	.Chapter_Chapter__1qHlj{
		margin:0 10px 0 10px;
		padding-bottom:60px;
	}
	.Chapter_Chapter__1qHlj h3{
		margin-top:10px;
	}
	.Chapter_verse__3X9sH{
		padding:5px 15px;
	}
	.Chapter_activeVerse__1n1qS{
		border-left:solid 3px var(--black-5);
		padding:5px 12px;
		margin-left:15px;
	}
}
