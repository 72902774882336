.Content {
	flex: 1 0;
	position: relative;
	top: 0;
	max-width: 100vw;
	padding-top: 15px;
}
.Home,
.Volume,
.Book,
.Chapter {
	display: flex;
	justify-content: center;
	margin: 40px;
}
.Nav {
	position: sticky;
	top: 40vh;
	z-index: 200;
}

.prev,
.next {
	position: absolute;
	display: flex;
	flex: 1;
	margin-top: 20px;
	padding: 30px;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	line-height: 0px;
	cursor: pointer;
	z-index: 201;
}
.prev div,
.next div {
	transform: scale(0.8, 1.2);
	pointer-events: none;
}
.prev:hover,
.next:hover {
	color: var(--black-3);
}
.next {
	right: 0;
}

/* media queries*/
@media (max-width: 600px) {
	.Content {
		padding-top: 0;
	}
	.Nav {
		top: calc(100vh - 40px);
		height: 42px;
		background: white;
		box-shadow: 0 0 5px var(--black-2-t);
	}
	.prev,
	.next {
		position: fixed;
		width: 50vw;
		bottom: 0;
		padding: 20px 0;
		background: white;
	}
	.prev {
		border-right: solid 0.5px var(--black-2);
	}
	.prev:hover,
	.next:hover {
		background-color: var(--white-5);
	}
}
