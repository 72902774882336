.Main{
	display:flex;
	position:relative;
	left:0;
	min-width:calc(100% + 300px);
	background-color:white;
	transition:min-width ease-in .3s;
}
.openAside{
	min-width:100%;
	transition:all ease-in .3s;
}
@media (min-width:900px){
	.Main{
		min-width:100%;
	}
	.openAside{
		min-width:calc(100% + 300px);
	}
}