.Title {
	height: 300px;
}
.Title h1,
.Title h2 {
	color: white;
}
.background {
	width: 100%;
	height: 300px;
	object-fit: cover;
	object-position: center top;
	position: relative;
	z-index: 400;
}
.overlay {
	height: 300px;
	position: relative;
	z-index: 401;
	top: -305px;
	right: 0;
	left: 0;
	background: var(--black-4-t);
}
.textContainer {
	position: relative;
	top: -610px;
	z-index: 402;
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	height: 300px;
}
.textWrap {
	max-width: 650px;
	margin: 40px;
	padding-right: 50px;
	border-right: 5px solid white;
	overflow: hidden;
}
.textWrap2 {
	text-align: right;
	-webkit-animation: slide-left 0.5s;
	-o-animation: slide-left 0.5s;
	animation: slide-left 0.5s;
}
@media (max-width: 665px) {
	.textContainer {
		justify-content: center;
	}
	.textWrap {
		padding-right: 0;
		border-right: none;
		overflow: hidden;
	}
	.textWrap h1 {
		border-bottom: 3px solid white;
		padding-bottom: 10px;
		font-size: 30px;
	}
	.textWrap h3 {
		font-size: 24px;
	}
	.textWrap2 {
		text-align: center;
		-webkit-animation: none;
		-o-animation: none;
		animation: none;
	}
}
@keyframes slide-left {
	from {
		opacity: 0;
		transform: translateX(250px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
