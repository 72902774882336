.Toolbar{
	background:white;
	position: -webkit-sticky;
	position: sticky;
	top:0;
	max-width:100vw;
	height:50px;
	display:flex;
	justify-content: center;
	align-items:center;
	z-index: 200;
	box-shadow: 0 0 5px var(--black-2-t);
}
.home{
	width:100%;
	padding:13px 10px 13px 40px;
	color:var(--black-8);
}
.reference{
	min-width: 150px;
	width:100%;
	text-align:center;
	padding:0 10px;
	color:var(--black-3);
}
.asideToggle{
	width:100%;
	text-align: right;
	padding:13px 40px 13px 10px;
	cursor:pointer;
}
.home:hover{
	color:var(--black-8);
}
@media(max-width:600px){
	.home{
		width:100%;
		min-width: 60px;
		text-align: center;
		padding:13px 0;
	}
	.reference{
		font-size: 12px;
	}
	.asideToggle{
		width:100%;
		min-width:60px;
		text-align: center;
		padding:13px 0;
}
}