.Volume {
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 60px;
}

.selectorWrap {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 800px;
	margin: 0 65px;
	flex: 1 0;
}
.selector {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
	padding: 10px;
	height: 40px;
	min-width: 200px;
	background: var(--blue-5);
	color: white;
	transition: transform 0.3s ease-in;
}
.selector:hover {
	color: white;
	background: var(--blue-3);
	transform: scale(1.15);
	box-shadow: 0 0 5px var(--black-2-t);
	z-index: 2;
	transition: transform 0.3s ease-in;
}

.source {
	display: block;
	margin-bottom: 20px;
	color: var(--black-3);
	text-align: right;
}

@media (max-width: 650px) {
	.selectorWrap {
		flex-direction: column;
		margin: 0;
		margin-top: 15px;
		width: 100%;
	}
	.selector {
		margin: 1px 0;
	}

	/*	.Chapter{
		margin:0 10px 0 10px;
		padding-bottom:60px;
	}
	.verse{
		padding:5px 15px;
	}
	.activeVerse{
		border-left:solid 3px var(--black-5);
		padding:5px 12px;
		margin-left:15px;
	}*/
}
