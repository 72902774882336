@import url("https://fonts.googleapis.com/css?family=Open+Sans|Montserrat:300,500&display=swap");

body {
	margin: 0;
	font-family: "open sans", sans-serif;
	color: var(--black-8);
	width: 100%;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
}
h1,
h2,
h3,
h4 {
	font-family: "Montserrat", sans-serif;
	margin: 10px;
	font-weight: 500;
	color: var(--black-8);
}
h1 {
	font-size: 46px;
	letter-spacing: 0.1em;
	font-weight: 400;
	/*	text-transform: uppercase;
*/
}
p {
	font-size: 18px;
	line-height: 1.7em;
	font-weight: 300;
}
hr {
	margin: 10px;
	border: 1px solid var(--black-1);
}
a {
	text-decoration: none;
	color: var(--blue-5);
}
a:hover {
	color: var(--blue-3);
}
#App {
	height: 100vh;
	box-sizing: content-box;
	overflow-y: scroll;
	overflow-x: hidden;
}

/*======GLOBAL IDS======*/
#Loading {
	position: absolute;
	top: 53px;
	bottom: 0;
	right: 5px;
	left: 0;
	z-index: 1000;
	background-color: white;
}
#NotFound {
	position: fixed;
	height: 100vh;
	top: 0;
	bottom: 0;
	right: 3px;
	left: 0;
	z-index: 1000;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
#NotFound h3 {
}
#NotFound a {
	padding: 20px 40px;
}

/*=====GLOBAL CLASSES=====*/
.fade-in {
	animation: fade-in 0.5s;
	height: 100%;
}

/*=====GLOBAL ANIMATIONS=====*/
@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/*=====Variables=====*/
:root {
	--white-1: #fcfcfc;
	--white-5: #f2f2f2;
	--white-9: #e8e8e8;
	--black-1: #e5e5e5;
	--black-2: #cccccc;
	--black-3: #b2b2b2;
	--black-4: #999999;
	--black-5: #7f7f7f;
	--black-6: #666666;
	--black-7: #4c4c4c;
	--black-8: #333333;
	--black-9: #191919;
	--black-1-t: rgba(0, 0, 0, 0.1);
	--black-2-t: rgba(0, 0, 0, 0.2);
	--black-3-t: rgba(0, 0, 0, 0.3);
	--black-4-t: rgba(0, 0, 0, 0.4);
	--black-5-t: rgba(0, 0, 0, 0.5);
	--black-6-t: rgba(0, 0, 0, 0.6);
	--black-7-t: rgba(0, 0, 0, 0.7);
	--black-8-t: rgba(0, 0, 0, 0.8);
	--black-9-t: rgba(0, 0, 0, 0.9);
	--blue-3: #58b3e2;
	--blue-5: rgb(2, 140, 210);
	--blue-5-t: rgba(2, 140, 210, 0.7);
}

/*experimental*/
* {
	-webkit-overflow-scrolling: touch;
}
