.Body{
	width:100vw;
	position:relative;
	opacity:1;
	transition:opacity 0s ease-in .3s;
}


@media(max-width:900px){
	.openAside{
		opacity: 0;
		transition:opacity 0s ease-in 0s;
	}
}