.Home {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.Home img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	object-position: center top;
}
.container {
	margin: 20px 60px;
	max-width: 600px;
}
.volumeList {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.title {
	position: absolute;
	top: 0;
	z-index: 1;
	padding: 5px;
	margin: 0;
	height: 200px;
	text-align: center;
	display: flex;
	align-items: center;
	pointer-events: none;
	color: white;
}
.volume {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.3s ease-in;
	min-width: 300px;
	max-width: 50%;
}

/* .volume:nth-last-child(1):nth-child(odd) {
	max-width: 100%;
} */
.overlay {
	height: 200px;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background: var(--black-3-t);
	transition: background 0.3s ease-in;
}
.volume:hover {
	transform: scale(1.1);
	box-shadow: 0 0 5px var(--black-2-t);
	z-index: 2;
	transition: transform 0.3s ease-in;
}
.volume:hover .overlay {
	background: var(--black-1-t);
	transition: background 0.3s ease-in;
}
.quote {
	margin-top: 20px;
	line-height: 1.5em;
	-webkit-animation: slide-left 2s;
	-o-animation: slide-left 2s;
	animation: slide-left 2s;
}
.source {
	margin-bottom: 20px;
	color: var(--black-3);
	text-align: right;
	-webkit-animation: slide-right 2s;
	-o-animation: slide-right 2s;
	animation: slide-right 2s;
}

@keyframes slide-left {
	from {
		opacity: 0;
		transform: translateX(100px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
@keyframes slide-right {
	from {
		opacity: 0;
		transform: translateX(-100px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@media (max-width: 650px) {
	.container {
		margin: 20px;
	}
	.volumeList {
		flex-direction: column;
	}
	.volume {
		max-width: 100%;
	}
}
